import React from 'react';
import { Button, Container, Grid, TextField, Typography } from '@material-ui/core';

export default class CurrentUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _method: "patch",
            password: "",
            password_confirmation: ""
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model}_`, "");

        this.setState({
            [id]: value
        });
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        return (
            <Container>
                <Typography variant="h5">Trocar Senha</Typography>

                <form
                    action={ this.props.update_path }
                    acceptCharset="UTF-8"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        {
                            this.props.id && this.state._method ?
                                <TextField
                                    id="_method"
                                    name="_method"
                                    type="hidden"
                                    value={this.state._method}>
                                </TextField> :
                                ""
                        }

                        {inputToken}

                        <Grid
                            item>
                            <TextField
                                label="Nome"
                                id="user_name"
                                value={this.props.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                                helperText={this.props.errors.name}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Email"
                                id="user_email"
                                value={this.props.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                helperText={this.props.errors.email}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Senha"
                                id="user_password"
                                name="user[password]"
                                error={this.props.errors.password.length > 0}
                                value={this.state.password}
                                type="password"
                                helperText={this.props.errors.password}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Confirma Senha"
                                id="user_password_confirmation"
                                name="user[password_confirmation]"
                                error={this.props.errors.password_confirmation.length > 0}
                                value={this.state.password_confirmation}
                                type="password"
                                helperText={this.props.errors.password_confirmation}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Atualizar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}