import React from 'react';
import { default as NumberFormat } from 'react-number-format';

function maskDateComponent(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            mask="_"
            format="##/##/####"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        id: props.id,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

export default maskDateComponent;