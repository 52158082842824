import React from 'react';
import { Button, Container, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import maskDateComponent from './Utils/maskDateComponent';

export default class InvoiceSendEmailForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        return (
            <Container>
                <Typography variant="h5">Enviar Emails das Faturas</Typography>

                <form
                    action={this.props.invoice_email_by_competence_date_path}
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        {inputToken}

                        <Grid item>
                            <TextField
                                label="Data de Competência"
                                id="competence_date_eq"
                                name="q[competence_date_eq]"
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskDateComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Enviar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}