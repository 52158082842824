import React from 'react';
import { Button, Container, InputLabel, Grid, Select, TextField } from '@material-ui/core';
import dateFormatter from './Utils/dateFormatter';
import maskDateComponent from './Utils/maskDateComponent';
import SearchFormTitle from './SearchFormTitle';

export default class BillingSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            created_at_gteq: dateFormatter(props.created_at_gteq) || '',
            created_at_lteq: dateFormatter(props.created_at_lteq) || '',

            due_date_gteq: dateFormatter(props.due_date_gteq) || '',
            due_date_lteq: dateFormatter(props.due_date_lteq) || '',

            cancel_date_gteq: dateFormatter(props.cancel_date_gteq) || '',
            cancel_date_lteq: dateFormatter(props.cancel_date_lteq) || '',

            payment_date_gteq: dateFormatter(props.payment_date_gteq) || '',
            payment_date_lteq: dateFormatter(props.payment_date_lteq) || '',

            invoice_contract_client_name_cont: props.invoice_contract_client_name_cont || '',

            status_eq: props.status_eq || '',
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });
    }

    render() {

        const optionsForStatusSelect = this.props.status_options.map((option, i) => {
            return (
                <option key={`status_option_${option.value}`} value={option.value}>
                    { option.label}
                </option>
            );
        });

        const titleItem = (
            <SearchFormTitle
                {...this.props}
            />
        );

        return (
            <Container>
                { this.props.title ? titleItem : ""}

                <form
                    action={this.props.search_path}
                    acceptCharset="UTF-8"
                    method="get">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Emissão Inicial"
                                        id="created_at_gteq"
                                        name="q[created_at_gteq]"
                                        value={this.state.created_at_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Emissão Final"
                                        id="created_at_lteq"
                                        name="q[created_at_lteq]"
                                        value={this.state.created_at_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Vencimento Inicial"
                                        id="due_date_gteq"
                                        name="q[due_date_gteq]"
                                        value={this.state.due_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Vencimento Final"
                                        id="due_date_lteq"
                                        name="q[due_date_lteq]"
                                        value={this.state.due_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Cancelamento Inicial"
                                        id="cancel_date_gteq"
                                        name="q[cancel_date_gteq]"
                                        value={this.state.cancel_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Cancelamento Final"
                                        id="cancel_date_lteq"
                                        name="q[cancel_date_lteq]"
                                        value={this.state.cancel_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Pagamento Inicial"
                                        id="payment_date_gteq"
                                        name="q[payment_date_gteq]"
                                        value={this.state.payment_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Pagamento Final"
                                        id="payment_date_lteq"
                                        name="q[payment_date_lteq]"
                                        value={this.state.payment_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>
                                
                                <Grid
                                    item>
                                    <InputLabel id={`status_eq_label`}>Status</InputLabel>
                                    <Select
                                        labelId={`status_eq_label`}
                                        id={`status_eq`}
                                        native
                                        name={`q[status_eq]`}
                                        value={this.state.status_eq || ""}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="">Selecione um Status</option>

                                        { optionsForStatusSelect }
                                    </Select>
                                </Grid>

                                <Grid
                                    item>
                                    <TextField
                                        label="Razão Social"
                                        id="invoice_contract_client_name_cont"
                                        name="q[invoice_contract_client_name_cont]"
                                        value={this.state.invoice_contract_client_name_cont}
                                        onChange={this.handleInputChange}>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Filtrar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Limpar Filtros
                                    </Button>
                                </Grid>

                                {
                                    this.props.export_xlsx_url ?
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                data-turbolinks="false"
                                                href={this.props.export_xlsx_url}>
                                                Exportar XLSX
                                           </Button>
                                        </Grid> :
                                        ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}