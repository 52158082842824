import React from 'react';
import { Button, Container, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';

export default class UserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name || '',
            email: props.email || '',
            role: props.role || '',
            _method: "patch"
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model}_`, "");

        this.setState({
            [id]: value
        });
    }

    handleDestroy(event) {
        if (window.confirm("Tem certeza que deseja excluir ?")) {
            this.formDestroy.submit();
        }
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        const optionsForRoleSelect = this.props.role_options.map((option, i) => {
            return (
                <option key={`role_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        return (
            <Container>
                <Typography variant="h5">{this.props.id ? "Editar Usuário" : "Novo Usuário"}</Typography>

                <form
                    action={this.props.id ? this.props.update_path : this.props.index_path}
                    acceptCharset="UTF-8"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        {
                            this.props.id && this.state._method ?
                                <TextField
                                    id="_method"
                                    name="_method"
                                    type="hidden"
                                    value={this.state._method}>
                                </TextField> :
                                ""
                        }

                        {inputToken}

                        <Grid
                            item>
                            <TextField
                                label="Nome"
                                id="user_name"
                                name="user[name]"
                                error={this.props.errors.name.length > 0}
                                value={this.state.name}
                                helperText={this.props.errors.name}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Email"
                                id="user_email"
                                name="user[email]"
                                error={this.props.errors.email.length > 0}
                                value={this.state.email}
                                helperText={this.props.errors.email}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <InputLabel id="user_role_label" error={this.props.errors.role.length > 0}>Perfil</InputLabel>
                            <Select
                                labelId="user_role_label"
                                id="user_role"
                                native
                                name="user[role]"
                                error={this.props.errors.role.length > 0}
                                value={this.state.role || ""}
                                onChange={this.handleInputChange}
                            >
                                <option value="">Selecione um Perfil</option>
                                
                                { optionsForRoleSelect }
                            </Select>
                            { this.props.errors.role.length > 0 ? (<FormHelperText error={true}>{this.props.errors.role}</FormHelperText>) : "" }
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        {this.props.id ? "Atualizar" : "Criar"}
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>

                                { this.props.id ?
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={this.handleDestroy}>
                                            Excluir
                                        </Button>
                                    </Grid> : 
                                    ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                {this.props.id ?
                    <form
                        action={this.props.update_path}
                        ref={ref => this.formDestroy = ref}
                        acceptCharset="UTF-8"
                        method="post">

                        <TextField
                            id="_method"
                            name="_method"
                            type="hidden"
                            value="delete">
                        </TextField>

                        {inputToken}
                    </form> :
                    ""
                }
            </Container>
        );
    }
}