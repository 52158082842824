import React from 'react';
import PropTypes from 'prop-types';

import ReactTable from 'react-table-6';
import { Box, Fab, Grid, Link, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EmailIcon from '@material-ui/icons/Email';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ReceiptIcon from '@material-ui/icons/Receipt';
import axios from 'axios';

export default class ReactDataTable extends React.Component {
  constructor(props) {
    super(props);

    let columns = [];
    if (this.props.columns) {
      columns = _.map(this.props.columns, (c) => {
        if (c['linkAccessor']) {
          if (c['linkAccessor'] == 'url') {
            c['Cell'] = row => (
              <Link
                href={row.original[c['linkAccessor']]}>
                <EditIcon />
              </Link>
            );
          } else if (c['linkAccessor'] == 'pdf') {
            c['Cell'] = row => (
              <Link
                href={row.original[c['accessor']]}
                data-turbolinks="false">
                <PictureAsPdfIcon />
              </Link>
            );
          } else if (c['linkAccessor'] == 'cancel') {
            c['Cell'] = row => (
              row.original[c['accessor']] ? 
              <Link
                href={row.original[c['accessor']]}
                data-turbolinks="false">
                <CancelIcon />
              </Link> : 
              ""
            );
          } else if (c['linkAccessor'] == 'email') {
            c['Cell'] = row => (
              <Link
                href={row.original[c['accessor']]}
                data-turbolinks="false">
                <EmailIcon />
              </Link>
            );
          } else if (c['linkAccessor'] == 'findinpage') {
            c['Cell'] = row => (
              <Link
                href={row.original[c['accessor']]}
                data-turbolinks="false">
                <FindInPageIcon />
              </Link>
            );
          } else if (c['linkAccessor'] == 'invoice') {
            c['Cell'] = row => (
              <Link
                href={row.original[c['accessor']]}
                data-turbolinks="false">
                <ReceiptIcon />
              </Link>
            );
          } else {
            c['Cell'] = row => (
              <Link
                href={row.original[c['linkAccessor']]}>
                {row.original[c['accessor']]}
              </Link>
            );
          }          
        }

        return c;
      });
    }

    this.state = {
      loading: false,
      first: true,
      columns: columns,
      data: this.props.data,
      page: this.props.page,
      pages: this.props.pages,
      totalCount: this.props.total_count,
    };
  }

  fetchData(state, instance) {
    if (this.props.url && !this.state.first) {
      this.setState({ loading: true });

      const columns = _.flattenDeep(_.map(this.props.columns, function (o) {
        if (o.columns) {
          return _.map(o.columns, function(oo) { return oo; });
        } else {
          return o;
        }
      }));
      
      let sort = state.sorted.length == 1 ? state.sorted[0]['id'] : this.props.defaultSort;

      const column = _.find(columns, (c) => { return c.accessor == sort && c.sort !== false; })

      if (column) {
        sort = column['sort'];
      } else {
        sort = this.props.defaultSort;
      }

      let sortDirection = state.sorted.length == 1 ? (state.sorted[0]['desc'] ? 'desc' : 'asc') : this.props.defaultSortDirection;

      const url = this.props.url.split('?');
      let baseUrl = url[0];
      let queryString = url[0] ? `?${(url[1] ? url[1] : '')}&page=${(state.page + 1)}&per=${state.pageSize}&q[s]=${sort} ${sortDirection}` : '';

      axios.get(`${baseUrl}.json${queryString}`)
        .then((res) => {
          history.pushState({}, '', queryString);
          this.setState({
            data: res.data.rows,
            pages: res.data.pages,
            loading: false
          });
        });
    } else {
      this.setState({ first: false });
    }
  }

  render() {
    const {
      data,
      pages,
      loading,
      columns,
    } = this.state;

    const titleItem = this.props.title ? (
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        item>

        <Typography
          style={{display: "contents"}}
          variant="h4">
          {this.props.title}
        </Typography>
        {
          this.props.new_path ?
            <Fab
              color="primary"
              variant="extended"
              style={{ float: "right" }}
              href={this.props.new_path}>
              <AddIcon />
              Adicionar
            </Fab>
            : ""
        }

      </Grid>
    ) : ""

    return (
      <Grid
        direction="column"
        spacing={1}
        container>

        { this.props.title ? titleItem : ""}

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item>
          <ReactTable
            manual
            showPagination={this.props.showPagination}
            data={this.state.data}
            columns={this.state.columns}
            pages={this.state.pages}
            loading={this.state.loading}
            defaultPageSize={this.props.defaultPageSize}
            onFetchData={(state, instance) => this.fetchData(state, instance)}
            showPageSizeOptions={true}
            // Text
            previousText={'Anterior'}
            nextText={'Próximo'}
            loadingText={'Carregando'}
            noDataText={"Sem Resultado para Mostrar"}
            pageText={"Página"}
            ofText={"de"}
            rowsText={"linhas"}
          />
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item>
          <Typography
            variant="subtitle2">
            {`Mostrando ${this.state.data.length} de ${this.props.totalCount} registro(s)`}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

ReactDataTable.propTypes = {
  url: PropTypes.string,
  defaultSort: PropTypes.string,
  defaultSortDirection: PropTypes.string,
  showPagination: PropTypes.bool
};

ReactDataTable.defaultProps = {
  data: [],
  defaultSort: 'id',
  defaultSortDirection: 'asc',
  page: 1,
  pages: -1,
  defaultPageSize: 25,
  showPagination: true
};
