import React from 'react';
import { FormHelperText, InputLabel, Grid, Select, TextField, Typography } from '@material-ui/core';
import { default as NumberFormat } from 'react-number-format';

function maskCEPComponent(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            mask="_"
            format="#####-###"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        id: props.id,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

export default class AddressForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zip_code: props.zip_code || '',
            street: props.street || '',
            complement: props.complement || '',
            number: props.number || '',
            neighborhood: props.neighborhood || '',
            city_id: props.city_id || '',
            id: props.id || ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model_name}_address_attributes_`, "");

        this.setState({
            [id]: value
        });
    }

    render() {

        const optionsForCitySelect = this.props.city_options.map((option, i) => {
            return (
                <option key={`city_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        return (
            <Grid container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                
                <input
                    type="hidden"
                    id={`${this.props.model_name}_address_attributes_id`}
                    name={`${this.props.model_name}[address_attributes][id]`}
                    value={this.state.id}
                    onChange={this.handleInputChange} />

                <Grid
                    item>
                    <Typography variant="subtitle1">Endereço</Typography>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="CEP"
                        id={`${this.props.model_name}_address_attributes_zip_code`}
                        name={`${this.props.model_name}[address_attributes][zip_code]`}
                        error={this.props.errors.zip_code.length > 0}
                        value={this.state.zip_code}
                        helperText={this.props.errors.zip_code}
                        onChange={this.handleInputChange}
                        InputProps={{
                            inputComponent: maskCEPComponent
                        }}>
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="Rua"
                        id={`${this.props.model_name}_address_attributes_street`}
                        name={`${this.props.model_name}[address_attributes][street]`}
                        error={this.props.errors.street.length > 0}
                        value={this.state.street}
                        helperText={this.props.errors.street}
                        onChange={this.handleInputChange}>
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="Número"
                        id={`${this.props.model_name}_address_attributes_number`}
                        name={`${this.props.model_name}[address_attributes][number]`}
                        error={this.props.errors.number.length > 0}
                        value={this.state.number}
                        helperText={this.props.errors.number}
                        onChange={this.handleInputChange}>
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="Complemento"
                        id={`${this.props.model_name}_address_attributes_complement`}
                        name={`${this.props.model_name}[address_attributes][complement]`}
                        error={this.props.errors.complement.length > 0}
                        value={this.state.complement}
                        helperText={this.props.errors.complement}
                        onChange={this.handleInputChange}>
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="Bairro"
                        id={`${this.props.model_name}_address_attributes_neighborhood`}
                        name={`${this.props.model_name}[address_attributes][neighborhood]`}
                        error={this.props.errors.neighborhood.length > 0}
                        value={this.state.neighborhood}
                        helperText={this.props.errors.neighborhood}
                        onChange={this.handleInputChange}>
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <InputLabel id={`${this.props.model_name}_address_attributes_city_id_label`} error={this.props.errors.city.length > 0}>Cidade</InputLabel>
                    <Select
                        labelId={`${this.props.model_name}_address_attributes_city_id_label`}
                        id={`${this.props.model_name}_address_attributes_city_id`}
                        native
                        name={`${this.props.model_name}[address_attributes][city_id]`}
                        error={this.props.errors.city.length > 0}
                        value={this.state.city_id || ""}
                        onChange={this.handleInputChange}
                    >
                        <option value="">Selecione uma Cidade</option>
                        
                        { optionsForCitySelect }
                    </Select>
                    { this.props.errors.city.length > 0 ? (<FormHelperText error={true}>{this.props.errors.city}</FormHelperText>) : "" }
                </Grid>

            </Grid>
        );
    }
}