import React from 'react';
import { Button, Container, InputLabel, Grid, Select, TextField } from '@material-ui/core';
import InvoiceSearchFormTitle from './InvoiceSearchFormTitle';
import dateFormatter from './Utils/dateFormatter';
import maskDateComponent from './Utils/maskDateComponent';

export default class InvoiceSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            billing_date_gteq: dateFormatter(props.billing_date_gteq) || '',
            billing_date_lteq: dateFormatter(props.billing_date_lteq) || '',

            competence_date_gteq: dateFormatter(props.competence_date_gteq) || '',
            competence_date_lteq: dateFormatter(props.competence_date_lteq) || '',

            payment_date_gteq: dateFormatter(props.payment_date_gteq) || '',
            payment_date_lteq: dateFormatter(props.payment_date_lteq) || '',

            contract_client_id_eq: props.contract_client_id_eq || '',
            contract_service_type_id_eq: props.contract_service_type_id_eq || '',
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });
    }

    render() {
        const optionsForClientSelect = this.props.client_options.map((option, i) => {
            return (
                <option key={`client_option_${option.value}`} value={option.value}>
                    { option.label}
                </option>
            );
        });

        const optionsForServiceTypeSelect = this.props.service_type_options.map((option, i) => {
            return (
                <option key={`service_type_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        const titleItem = (
            <InvoiceSearchFormTitle
                {...this.props}
            />
        );

        return (
            <Container>
                { this.props.title ? titleItem : ""}

                <form
                    action={this.props.search_path}
                    acceptCharset="UTF-8"
                    method="get">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Cobrança Inicial"
                                        id="billing_date_gteq"
                                        name="q[billing_date_gteq]"
                                        value={this.state.billing_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Cobrança Final"
                                        id="billing_date_lteq"
                                        name="q[billing_date_lteq]"
                                        value={this.state.billing_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Competência Inicial"
                                        id="competence_date_gteq"
                                        name="q[competence_date_gteq]"
                                        value={this.state.competence_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Competência Final"
                                        id="competence_date_lteq"
                                        name="q[competence_date_lteq]"
                                        value={this.state.competence_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Pagamento Inicial"
                                        id="payment_date_gteq"
                                        name="q[payment_date_gteq]"
                                        value={this.state.payment_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Pagamento Final"
                                        id="payment_date_lteq"
                                        name="q[payment_date_lteq]"
                                        value={this.state.payment_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>
                                                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>
                                                                <Grid
                                item>
                                <InputLabel id={`contract_service_type_id_eq_label`}>Tipo de Serviço</InputLabel>
                                <Select
                                    labelId={`contract_service_type_id_eq_label`}
                                    id={`contract_service_type_id_eq`}
                                    native
                                    name={`q[contract_service_type_id_eq]`}
                                    value={this.state.contract_service_type_id_eq || ""}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="">Selecione um Tipo de Serviço</option>

                                    {optionsForServiceTypeSelect}
                                </Select>
                            </Grid>

                            <Grid
                                item>
                                <InputLabel id={`contract_client_id_eq_label`}>Cliente</InputLabel>
                                <Select
                                    labelId={`contract_client_id_eq_label`}
                                    id={`contract_client_id_eq`}
                                    native
                                    name={`q[contract_client_id_eq]`}
                                    value={this.state.contract_client_id_eq || ""}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="">Selecione um Cliente</option>

                                    {optionsForClientSelect}
                                </Select>
                            </Grid>
                                </Grid>

                        </Grid>

                        <Grid
                            item>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Filtrar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Limpar Filtros
                                    </Button>
                                </Grid>

                                {
                                    this.props.export_xlsx_url ?
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                data-turbolinks="false"
                                                href={this.props.export_xlsx_url}>
                                                Exportar XLSX
                                           </Button>
                                        </Grid> :
                                        ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}