import React from 'react';
import { Button, Container, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';

export default class CityForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name || '',
            state_id: props.state_id || '',
            code: props.code || '',
            _method: "patch"
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model}_`, "");

        this.setState({
            [id]: value
        });
    }

    handleDestroy(event) {
        if (window.confirm("Tem certeza que deseja excluir ?")) {
            this.formDestroy.submit();
        }
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        const optionsForStateSelect = this.props.state_options.map((option, i) => {
            return (
                <option key={`state_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        return (
            <Container>
                <Typography variant="h5">{this.props.id ? "Editar Cidade" : "Nova Cidade"}</Typography>

                <form
                    action={this.props.id ? this.props.update_path : this.props.index_path}
                    acceptCharset="UTF-8"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        {
                            this.props.id && this.state._method ?
                                <TextField
                                    id="_method"
                                    name="_method"
                                    type="hidden"
                                    value={this.state._method}>
                                </TextField> :
                                ""
                        }

                        {inputToken}

                        <Grid
                            item>
                            <TextField
                                label="Nome"
                                id="city_name"
                                name="city[name]"
                                error={this.props.errors.name.length > 0}
                                value={this.state.name}
                                helperText={this.props.errors.name}
                                fullWidth={true}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <InputLabel id="city_state_id_label" error={this.props.errors.state.length > 0}>Estado</InputLabel>
                            <Select
                                labelId="city_state_id_label"
                                id="city_state_id"
                                native
                                name="city[state_id]"
                                error={this.props.errors.state.length > 0}
                                value={this.state.state_id || ""}
                                onChange={this.handleInputChange}
                            >
                                <option value="">Selecione um Estado</option>
                                
                                { optionsForStateSelect }
                            </Select>
                            { this.props.errors.state.length > 0 ? (<FormHelperText error={true}>{this.props.errors.state}</FormHelperText>) : "" }
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Código"
                                id="city_code"
                                name="city[code]"
                                error={this.props.errors.code.length > 0}
                                value={this.state.code}
                                helperText={this.props.errors.code}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        {this.props.id ? "Atualizar" : "Criar"}
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>

                                {this.props.id ?
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={this.handleDestroy}>
                                            Excluir
                                        </Button>
                                    </Grid> :
                                    ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                {this.props.id ?
                    <form
                        action={this.props.update_path}
                        ref={ref => this.formDestroy = ref}
                        acceptCharset="UTF-8"
                        method="post">

                        <TextField
                            id="_method"
                            name="_method"
                            type="hidden"
                            value="delete">
                        </TextField>

                        {inputToken}
                    </form> :
                    ""
                }
            </Container>
        );
    }
}