import React from 'react';
import { AppBar, Box, Container, Divider, Drawer, IconButton, Link, List, ListItem, Menu, MenuItem, ListSubheader, ListItemText, Toolbar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';

export default class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            profile: false,
            anchorEl: null
        }

        this.handleDrawerState = this.handleDrawerState.bind(this);
        this.handleProfileState = this.handleProfileState.bind(this);
    }

    handleDrawerState() {
        this.setState({ open: !this.state.open })
    };

    handleProfileState(event) {
        const profile = this.state.profile;
        this.setState({ profile: !profile, anchorEl: !profile ? event.currentTarget : null });
    };

    render() {
        const menuItems = [];

        for (let section of Object.keys(this.props.links)) {
            let menuItem = this.props.links[section];

            if (menuItem.links.length > 0) {
                menuItems.push(
                    <div key={`${section}_list`}>
                        <List
                            subheader={
                                <ListSubheader component="div" id={`${section}_list_subheader`}>
                                    {menuItem.label}
                                </ListSubheader>
                            }
                        >
                            {menuItem.links.map((link, id) => (
                                <ListItem button key={`${section}_${id}_list_item`} component="a" href={link.href}>
                                    <ListItemText primary={link.label} />
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                    </div>
                );
            }
        }

        const renderMenu = (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={`profile_menu`}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.profile}
                onClose={this.handleProfileState}
            >
                <MenuItem>
                    <Link href={this.props.edit_user_path}>
                    Trocar minha senha
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link href={this.props.logout_path}>
                        Sair
                    </Link>
                </MenuItem>
            </Menu>
        );

        return (
            <Container style={{ marginBottom: "80px" }}>
                <AppBar
                    position="fixed"
                >
                    <Toolbar>
                        <Box
                            display="flex"
                            flexGrow="1"
                            alignItems="center"
                        >
                            <IconButton
                                color="inherit"
                                onClick={this.handleDrawerState}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.props.system_name}
                            </Typography>
                        </Box>
                        <IconButton
                            color="inherit"
                            onClick={this.handleProfileState}
                        >
                            <AccountCircleIcon />
                            { renderMenu }
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="temporary"
                    open={this.state.open}
                >
                    <IconButton onClick={this.handleDrawerState}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <Divider />

                    { menuItems }
                </Drawer>                
            </Container>
        );
    }
}