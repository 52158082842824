import React from 'react';
import { Button, Container, Grid, TextField, Typography, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import AddressForm from "components/AddressForm";
import { default as NumberFormat } from 'react-number-format';

function maskCNPJComponent(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            mask="_"
            format="##.###.###/####-##"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        id: props.id,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

function maskPhoneComponent(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            mask="_"
            format="(##) #########"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        id: props.id,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

export default class ClientForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name || '',
            company_name: props.company_name || '',
            identifier: props.identifier || '',
            responsible: props.responsible || '',
            observation: props.observation || '',
            telephone: props.telephone || '',
            email: props.email || '',
            address: {
                street: props.address.street || '',
                number: props.address.number || '',
                neighborhood: props.address.neighborhood || '',
                complement: props.address.complement || '',
                city_id: props.address.city_id || '',
                zip_code: props.address.zip_code || ''
            },
            integration_code: props.integration_code || '',
            tax_retain_iss: props.tax_retain_iss,
            tax_ir: props.tax_ir,
            _method: "patch"
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model}_`, "");

        this.setState({
            [id]: value
        });
    }

    handleDestroy(event) {
        if (window.confirm("Tem certeza que deseja excluir ?")) {
            this.formDestroy.submit();
        }
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        return (
            <Container>
                <Typography variant="h5">{this.props.id ? "Editar Cliente" : "Novo Cliente"}</Typography>

                <form
                    action={this.props.id ? this.props.update_path : this.props.index_path}
                    acceptCharset="UTF-8"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        {
                            this.props.id && this.state._method ?
                                <TextField
                                    id="_method"
                                    name="_method"
                                    type="hidden"
                                    value={this.state._method}>
                                </TextField> :
                                ""
                        }

                        {inputToken}

                        <Grid
                            item>
                            <TextField
                                label="Nome Fantasia"
                                id="client_name"
                                name="client[name]"
                                error={this.props.errors.name.length > 0}
                                value={this.state.name}
                                helperText={this.props.errors.name}
                                fullWidth={true}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Razão Social"
                                id="client_company_name"
                                name="client[company_name]"
                                error={this.props.errors.company_name.length > 0}
                                value={this.state.company_name}
                                helperText={this.props.errors.company_name}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="CNPJ"
                                id="client_identifier"
                                name="client[identifier]"
                                error={this.props.errors.identifier.length > 0}
                                value={this.state.identifier}
                                helperText={this.props.errors.identifier}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskCNPJComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Responsável"
                                id="client_responsible"
                                name="client[responsible]"
                                error={this.props.errors.responsible.length > 0}
                                value={this.state.responsible}
                                helperText={this.props.errors.responsible}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Telefone"
                                id="client_telephone"
                                name="client[telephone]"
                                error={this.props.errors.telephone.length > 0}
                                value={this.state.telephone}
                                helperText={this.props.errors.telephone}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskPhoneComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Email"
                                id="client_email"
                                name="client[email]"
                                error={this.props.errors.email.length > 0}
                                value={this.state.email}
                                helperText={this.props.errors.email}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Observações"
                                id="client_observation"
                                name="client[observation]"
                                multiline
                                rowsMax={4}
                                rows={4}
                                error={this.props.errors.observation.length > 0}
                                value={this.state.observation}
                                helperText={this.props.errors.observation}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid 
                            item>
                            <AddressForm 
                                id={this.props.address.id}
                                model_id={this.props.id}
                                model_name={this.props.model}
                                street={this.props.address.street}
                                number={this.props.address.number}
                                neighborhood={this.props.address.neighborhood}
                                complement={this.props.address.complement}
                                city_id={this.props.address.city_id}
                                zip_code={this.props.address.zip_code}
                                city_options={this.props.city_options}
                                errors={this.props.errors.address}
                            />
                        </Grid>

                        <Grid
                            item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.tax_retain_iss} 
                                            onChange={this.handleInputChange} 
                                            value={"1"}
                                            id="client_tax_retain_iss" 
                                            name="client[tax_retain_iss]" />}
                                        label="Reter ISSQN ?"
                                    />
                                </FormGroup>
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Alíquota IR"
                                    id="client_tax_ir"
                                    name="client[tax_ir]"
                                    error={this.props.errors.tax_ir.length > 0}
                                    value={this.state.tax_ir}
                                    helperText={this.props.errors.tax_ir}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'client_tax_ir',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        {this.props.id ? "Atualizar" : "Criar"}
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>

                                { this.props.id ?
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={this.handleDestroy}>
                                            Excluir
                                        </Button>
                                    </Grid> : 
                                    ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                {this.props.id ?
                    <form
                        action={this.props.update_path}
                        ref={ref => this.formDestroy = ref}
                        acceptCharset="UTF-8"
                        method="post">

                        <TextField
                            id="_method"
                            name="_method"
                            type="hidden"
                            value="delete">
                        </TextField>

                        {inputToken}
                    </form> :
                    ""
                }
            </Container>
        );
    }
}