import React from 'react';
import { Button, Container, InputLabel, Grid, Select, TextField } from '@material-ui/core';
import SearchFormTitle from './SearchFormTitle';

export default class ClientSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name_cont: props.name_cont || '',
            company_name_cont: props.company_name_cont || '',
            responsible_cont: props.responsible_cont || '',
            address_city_id_eq: props.address_city_id_eq || ''
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id

        this.setState({
            [id]: value
        });
    }

    render() {
        const optionsForCitySelect = this.props.city_options.map((option, i) => {
            return (
                <option key={`city_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        const titleItem = (
            <SearchFormTitle
                {...this.props}
            />
        );

        return (
            <Container>
                { this.props.title ? titleItem : ""}

                <form
                    action={this.props.search_path}
                    acceptCharset="UTF-8"
                    method="get">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Nome Fantasia"
                                        id="name_cont"
                                        name="q[name_cont]"
                                        value={this.state.name_cont}
                                        onChange={this.handleInputChange}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Razão Social"
                                        id="company_name_cont"
                                        name="q[company_name_cont]"
                                        value={this.state.company_name_cont}
                                        onChange={this.handleInputChange}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>
                                <Grid
                                    item>
                                    <TextField
                                        label="Responsável"
                                        id="responsible_cont"
                                        name="q[responsible_cont]"
                                        value={this.state.responsible_cont}
                                        onChange={this.handleInputChange}>
                                    </TextField>
                                </Grid>

                                <Grid
                                    item>
                                    <InputLabel id={`address_attributes_city_id_label`}>Cidade</InputLabel>
                                    <Select
                                        labelId={`address_attributes_city_id_label`}
                                        id={`address_city_id_eq`}
                                        native
                                        name={`q[address_city_id_eq]`}
                                        value={this.state.address_city_id_eq || ""}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="">Selecione uma Cidade</option>

                                        {optionsForCitySelect}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Filtrar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Limpar Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}