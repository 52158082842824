import React from 'react';
import { Button, Container, InputLabel, Grid, Select, TextField } from '@material-ui/core';
import dateFormatter from './Utils/dateFormatter';
import maskDateComponent from './Utils/maskDateComponent';
import SearchFormTitle from './SearchFormTitle';

export default class EletronicInvoiceSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data_emissao_gteq: dateFormatter(props.data_emissao_gteq) || '',
            data_emissao_lteq: dateFormatter(props.data_emissao_lteq) || '',

            data_cancelamento_gteq: dateFormatter(props.data_cancelamento_gteq) || '',
            data_cancelamento_lteq: dateFormatter(props.data_cancelamento_lteq) || '',

            razao_social_tomador_cont: props.razao_social_tomador_cont || '',
            status_eq: props.status_eq || '',
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });
    }

    render() {

        const optionsForStatusSelect = this.props.status_options.map((option, i) => {
            return (
                <option key={`status_option_${option.value}`} value={option.value}>
                    { option.label}
                </option>
            );
        });

        const titleItem = (
            <SearchFormTitle
                {...this.props}
            />
        );

        return (
            <Container>
                { this.props.title ? titleItem : ""}

                <form
                    action={this.props.search_path}
                    acceptCharset="UTF-8"
                    method="get">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Emissão Inicial"
                                        id="data_emissao_gteq"
                                        name="q[data_emissao_gteq]"
                                        value={this.state.data_emissao_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Emissão Final"
                                        id="data_emissao_lteq"
                                        name="q[data_emissao_lteq]"
                                        value={this.state.data_emissao_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data de Cancelamento Inicial"
                                        id="data_cancelamento_gteq"
                                        name="q[data_cancelamento_gteq]"
                                        value={this.state.data_cancelamento_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data de Cancelamento Final"
                                        id="data_cancelamento_lteq"
                                        name="q[data_cancelamento_lteq]"
                                        value={this.state.data_cancelamento_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>
                                
                                <Grid
                                    item>
                                    <InputLabel id={`status_eq_label`}>Status</InputLabel>
                                    <Select
                                        labelId={`status_eq_label`}
                                        id={`status_eq`}
                                        native
                                        name={`q[status_eq]`}
                                        value={this.state.status_eq || ""}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="">Selecione um Status</option>

                                        { optionsForStatusSelect }
                                    </Select>
                                </Grid>

                                <Grid
                                    item>
                                    <TextField
                                        label="Razão Social Tomador"
                                        id="razao_social_tomador_cont"
                                        name="q[razao_social_tomador_cont]"
                                        value={this.state.razao_social_tomador_cont}
                                        onChange={this.handleInputChange}>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Filtrar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Limpar Filtros
                                    </Button>
                                </Grid>

                                {
                                    this.props.export_xlsx_url ?
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                data-turbolinks="false"
                                                href={this.props.export_xlsx_url}>
                                                Exportar XLSX
                                           </Button>
                                        </Grid> :
                                        ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}