import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Link, TextField, Typography } from '@material-ui/core';

export default class DeviseLoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: props.email,
            remember_me: props.remember_me,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });
    }

    render() {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid
                    item>
                    <Typography variant="h2">{this.props.system_name}</Typography>
                </Grid>

                <Grid
                    item>
                    <image src={this.props.logo}></image>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="Email"
                        id="email"
                        name="user[email]"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}>
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <TextField
                        label="Senha"
                        id="password"
                        name="user[password]"
                        value={this.state.password}
                        type="password">
                    </TextField>
                </Grid>

                <Grid
                    item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="user[remember_me]"
                                id="remember_me" value={1}
                                checked={this.state.remember_me}
                                onChange={this.handleInputChange} />}
                        label="Logar Automaticamente"
                    />
                </Grid>

                <Grid
                    item>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary">
                            Logar
                    </Button>
                </Grid>

                <Grid
                    item>
                    <Link 
                        href={this.props.forgot_your_password} 
                        color="textSecondary">
                            Esqueceu sua senha ?
                    </Link>
                </Grid>

                <Grid
                    item>
                    <Link 
                        href={this.props.did_not_receive_confirmation_instructions} 
                        color="textSecondary">
                            Não recebeu instruções para confirmação de usuário ?
                    </Link>
                </Grid>

            </Grid>
        );
    }
}