import React from 'react';
import { Button, Checkbox, Container, FormGroup, FormControlLabel, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { default as NumberFormat } from 'react-number-format';
import dateFormatter from './Utils/dateFormatter';
import maskDateComponent from './Utils/maskDateComponent';

export default class InvoiceForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contract_id: props.contract_id || '',
            billing_date: dateFormatter(props.billing_date) || '',
            payment_date: dateFormatter(props.payment_date) || '',
            competence_date: dateFormatter(props.competence_date) || '',
            plan: props.plan || '',
            level: props.level || '',
            original_value: props.original_value || '',
            pro_rata_value: props.pro_rata_value || '',
            discount_value: props.discount_value || '',
            surplus_value: props.surplus_value || '',
            charged_value: props.charged_value || '',
            fare_value: props.fare_value || '',
            paid_value: props.paid_value || '',
            call_number: props.call_number || '',
            surplus_call_number: props.surplus_call_number || '',
            observations: props.observations || '',
            invoice_number: props.invoice_number || '',
            force_create_eletronic_invoice: props.force_create_eletronic_invoice == '1',
            force_create_billing: props.force_create_billing == '1',
            released: props.released == '1',
            _method: "patch"
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model}_`, "");

        this.setState({
            [id]: value
        });
    }

    handleDestroy(event) {
        if (window.confirm("Tem certeza que deseja excluir ?")) {
            this.formDestroy.submit();
        }
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        const optionsForContractSelect = this.props.contract_options.map((option, i) => {
            return (
                <option key={`contract_option_${option.value}`} value={option.value}>
                    { option.label}
                </option>
            );
        });

        return (
            <Container>
                <Typography variant="h5">{this.props.id ? "Editar Fatura" : "Nova Fatura"}</Typography>

                <form
                    action={this.props.id ? this.props.update_path : this.props.index_path}
                    acceptCharset="UTF-8"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        {
                            this.props.id && this.state._method ?
                                <TextField
                                    id="_method"
                                    name="_method"
                                    type="hidden"
                                    value={this.state._method}>
                                </TextField> :
                                ""
                        }

                        {inputToken}

                        <Grid
                            item>
                            <InputLabel id={`invoice_contract_id_label`} error={this.props.errors.contract.length > 0}>Contrato</InputLabel>
                            <Select
                                labelId={`invoice_contract_id_label`}
                                id={`invoice_contract_id`}
                                native
                                name={`invoice[contract_id]`}
                                error={this.props.errors.contract.length > 0}
                                value={this.state.contract_id || ""}
                                onChange={this.handleInputChange}
                            >
                                <option value="">Selecione um Contrato</option>

                                {optionsForContractSelect}
                            </Select>
                            {this.props.errors.contract.length > 0 ? (<FormHelperText error={true}>{this.props.errors.contract}</FormHelperText>) : ""}
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Data de Competência"
                                id="invoice_competence_date"
                                name="invoice[competence_date]"
                                error={this.props.errors.competence_date.length > 0}
                                value={this.state.competence_date}
                                helperText={this.props.errors.competence_date}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskDateComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Data de Cobrança"
                                id="invoice_billing_date"
                                name="invoice[billing_date]"
                                error={this.props.errors.billing_date.length > 0}
                                value={this.state.billing_date}
                                helperText={this.props.errors.billing_date}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskDateComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Data de Pagamento"
                                id="invoice_payment_date"
                                name="invoice[payment_date]"
                                error={this.props.errors.payment_date.length > 0}
                                value={this.state.payment_date}
                                helperText={this.props.errors.payment_date}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskDateComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label={I18n.t('activerecord.attributes.invoice.plan')}
                                    id="invoice_plan"
                                    name="invoice[plan]"
                                    error={this.props.errors.plan.length > 0}
                                    value={this.state.plan}
                                    helperText={this.props.errors.plan}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_plan',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label={I18n.t('activerecord.attributes.invoice.level')}
                                    id="invoice_level"
                                    name="invoice[level]"
                                    error={this.props.errors.level.length > 0}
                                    value={this.state.level}
                                    helperText={this.props.errors.level}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_level',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label={I18n.t('activerecord.attributes.invoice.call_number')}
                                    id="invoice_call_number"
                                    name="invoice[call_number]"
                                    error={this.props.errors.call_number.length > 0}
                                    value={this.state.call_number}
                                    helperText={this.props.errors.call_number}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_call_number',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label={I18n.t('activerecord.attributes.invoice.surplus_call_number')}
                                    id="invoice_surplus_call_number"
                                    name="invoice[surplus_call_number]"
                                    error={this.props.errors.surplus_call_number.length > 0}
                                    value={this.state.surplus_call_number}
                                    helperText={this.props.errors.surplus_call_number}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_surplus_call_number',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Original"
                                    id="invoice_original_value"
                                    name="invoice[original_value]"
                                    error={this.props.errors.original_value.length > 0}
                                    value={this.state.original_value}
                                    helperText={this.props.errors.original_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_original_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Pró Rata"
                                    id="invoice_pro_rata_value"
                                    name="invoice[pro_rata_value]"
                                    error={this.props.errors.pro_rata_value.length > 0}
                                    value={this.state.pro_rata_value}
                                    helperText={this.props.errors.pro_rata_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_pro_rata_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Excedente"
                                    id="invoice_surplus_value"
                                    name="invoice[surplus_value]"
                                    error={this.props.errors.surplus_value.length > 0}
                                    value={this.state.surplus_value}
                                    helperText={this.props.errors.surplus_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_surplus_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Desconto"
                                    id="invoice_discount_value"
                                    name="invoice[discount_value]"
                                    error={this.props.errors.discount_value.length > 0}
                                    value={this.state.discount_value}
                                    helperText={this.props.errors.discount_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_discount_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Cobrado"
                                    id="invoice_charged_value"
                                    name="invoice[charged_value]"
                                    error={this.props.errors.charged_value.length > 0}
                                    value={this.state.charged_value}
                                    helperText={this.props.errors.charged_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_charged_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Pago"
                                    id="invoice_paid_value"
                                    name="invoice[paid_value]"
                                    error={this.props.errors.paid_value.length > 0}
                                    value={this.state.paid_value}
                                    helperText={this.props.errors.paid_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_paid_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor da Taxa"
                                    id="invoice_fare_value"
                                    name="invoice[fare_value]"
                                    error={this.props.errors.fare_value.length > 0}
                                    value={this.state.fare_value}
                                    helperText={this.props.errors.fare_value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'invoice_fare_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Observações"
                                id="invoice_observations"
                                name="invoice[observations]"
                                multiline
                                rowsMax={4}
                                rows={4}
                                error={this.props.errors.observations.length > 0}
                                value={this.state.observations}
                                helperText={this.props.errors.observations}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Número"
                                id="invoice_invoice_number"
                                name="invoice[invoice_number]"
                                error={this.props.errors.invoice_number.length > 0}
                                value={this.state.invoice_number}
                                helperText={this.props.errors.invoice_number}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.released} 
                                            onChange={this.handleInputChange} 
                                            value={"1"}
                                            id="invoice_released" 
                                            name="invoice[released]" />}
                                        label="Liberado para criação de cobrança ?"
                                    />
                                </FormGroup>
                        </Grid>

                        <Grid
                            item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.force_create_eletronic_invoice} 
                                            onChange={this.handleInputChange} 
                                            value={"1"}
                                            id="invoice_force_create_eletronic_invoice" 
                                            name="invoice[force_create_eletronic_invoice]" />}
                                        label="Força emissão de nota fiscal eletrônica ?"
                                    />
                                </FormGroup>
                        </Grid>

                        <Grid
                            item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.force_create_billing} 
                                            onChange={this.handleInputChange} 
                                            value={"1"}
                                            id="invoice_force_create_billing" 
                                            name="invoice[force_create_billing]" />}
                                        label="Força emissão de cobrança ?"
                                    />
                                </FormGroup>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        {this.props.id ? "Atualizar" : "Criar"}
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>

                                {this.props.id ?
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={this.handleDestroy}>
                                            Excluir
                                        </Button>
                                    </Grid> :
                                    ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                {this.props.id ?
                    <form
                        action={this.props.update_path}
                        ref={ref => this.formDestroy = ref}
                        acceptCharset="UTF-8"
                        method="post">

                        <TextField
                            id="_method"
                            name="_method"
                            type="hidden"
                            value="delete">
                        </TextField>

                        {inputToken}
                    </form> :
                    ""
                }
            </Container>
        );
    }
}