import React from 'react';
import { Button, Container, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { default as NumberFormat } from 'react-number-format';
import dateFormatter from './Utils/dateFormatter';
import maskDateComponent from './Utils/maskDateComponent';

export default class ContractForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client_id: props.client_id || '',
            service_type_id: props.service_type_id || '',
            billing_day: props.billing_day || '',
            start_date: dateFormatter(props.start_date) || '',
            end_date: dateFormatter(props.end_date) || '',
            plan: props.plan || '',
            level: props.level || '',
            value: props.value || '',
            observations: props.observations || '',
            surplus_value_per_call: props.surplus_value_per_call || '',
            _method: "patch"
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id.replace(`${this.props.model}_`, "");

        this.setState({
            [id]: value
        });
    }

    handleDestroy(event) {
        if (window.confirm("Tem certeza que deseja excluir ?")) {
            this.formDestroy.submit();
        }
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        const optionsForClientSelect = this.props.client_options.map((option, i) => {
            return (
                <option key={`client_option_${option.value}`} value={option.value}>
                    { option.label}
                </option>
            );
        });

        const optionsForServiceTypeSelect = this.props.service_type_options.map((option, i) => {
            return (
                <option key={`service_type_option_${option.value}`} value={option.value}>
                    { option.label}
                </option>
            );
        });

        return (
            <Container>
                <Typography variant="h5">{this.props.id ? "Editar Contrato" : "Novo Contrato"}</Typography>

                <form
                    action={this.props.id ? this.props.update_path : this.props.index_path}
                    acceptCharset="UTF-8"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        {
                            this.props.id && this.state._method ?
                                <TextField
                                    id="_method"
                                    name="_method"
                                    type="hidden"
                                    value={this.state._method}>
                                </TextField> :
                                ""
                        }

                        {inputToken}

                        <Grid
                            item>
                            <InputLabel id={`contract_client_id_label`} error={this.props.errors.client.length > 0}>Cliente</InputLabel>
                            <Select
                                labelId={`contract_client_id_label`}
                                id={`contract_client_id`}
                                native
                                name={`contract[client_id]`}
                                error={this.props.errors.client.length > 0}
                                value={this.state.client_id || ""}
                                onChange={this.handleInputChange}
                            >
                                <option value="">Selecione um Cliente</option>

                                {optionsForClientSelect}
                            </Select>
                            {this.props.errors.client.length > 0 ? (<FormHelperText error={true}>{this.props.errors.client}</FormHelperText>) : ""}
                        </Grid>

                        <Grid
                            item>
                            <InputLabel id={`contract_service_type_id_label`} error={this.props.errors.client.length > 0}>Tipo de Serviço</InputLabel>
                            <Select
                                labelId={`contract_service_type_id_label`}
                                id={`contract_service_type_id`}
                                native
                                name={`contract[service_type_id]`}
                                error={this.props.errors.service_type.length > 0}
                                value={this.state.service_type_id || ""}
                                onChange={this.handleInputChange}
                            >
                                <option value="">Selecione um Tipo de Serviço</option>

                                {optionsForServiceTypeSelect}
                            </Select>
                            {this.props.errors.service_type.length > 0 ? (<FormHelperText error={true}>{this.props.errors.service_type}</FormHelperText>) : ""}
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Data Inicial"
                                id="contract_start_date"
                                name="contract[start_date]"
                                error={this.props.errors.start_date.length > 0}
                                value={this.state.start_date}
                                helperText={this.props.errors.start_date}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskDateComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Data Final"
                                id="contract_end_date"
                                name="contract[end_date]"
                                error={this.props.errors.end_date.length > 0}
                                value={this.state.end_date}
                                helperText={this.props.errors.end_date}
                                onChange={this.handleInputChange}
                                InputProps={{
                                    inputComponent: maskDateComponent
                                }}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Plano"
                                    id="contract_plan"
                                    name="contract[plan]"
                                    error={this.props.errors.plan.length > 0}
                                    value={this.state.plan}
                                    helperText={this.props.errors.plan}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'contract_plan',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Nível"
                                    id="contract_level"
                                    name="contract[level]"
                                    error={this.props.errors.level.length > 0}
                                    value={this.state.level}
                                    helperText={this.props.errors.level}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'contract_level',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>                        

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor"
                                    id="contract_value"
                                    name="contract[value]"
                                    error={this.props.errors.value.length > 0}
                                    value={this.state.value}
                                    helperText={this.props.errors.value}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'contract_value',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                                <NumberFormat
                                    label="Valor Excedente por Chamada"
                                    id="contract_surplus_value_per_call"
                                    name="contract[surplus_value_per_call]"
                                    error={this.props.errors.surplus_value_per_call.length > 0}
                                    value={this.state.surplus_value_per_call}
                                    helperText={this.props.errors.surplus_value_per_call}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    fixedDecimalScale={true}
                                    isNumericString={true}
                                    onValueChange={(values) => {
                                        this.handleInputChange({
                                            target: {
                                                id: 'contract_surplus_value_per_call',
                                                type: 'text',
                                                value: values.value,
                                            },
                                        });
                                    }}
                                />
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Dia do Faturamento"
                                id="contract_billing_day"
                                name="contract[billing_day]"
                                error={this.props.errors.billing_day.length > 0}
                                value={this.state.billing_day}
                                helperText={this.props.errors.billing_day}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>
                            <TextField
                                label="Observações"
                                id="contract_observations"
                                name="contract[observations]"
                                multiline
                                rowsMax={4}
                                rows={4}
                                error={this.props.errors.observations.length > 0}
                                value={this.state.observations}
                                helperText={this.props.errors.observations}
                                onChange={this.handleInputChange}>
                            </TextField>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        {this.props.id ? "Atualizar" : "Criar"}
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>

                                {this.props.id ?
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={this.handleDestroy}>
                                            Excluir
                                        </Button>
                                    </Grid> :
                                    ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                {this.props.id ?
                    <form
                        action={this.props.update_path}
                        ref={ref => this.formDestroy = ref}
                        acceptCharset="UTF-8"
                        method="post">

                        <TextField
                            id="_method"
                            name="_method"
                            type="hidden"
                            value="delete">
                        </TextField>

                        {inputToken}
                    </form> :
                    ""
                }
            </Container>
        );
    }
}