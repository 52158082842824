import React from 'react';
import { Box, Fab, Grid, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import EmailIcon from '@material-ui/icons/Email';

export default class InvoiceSearchFormTitle extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return this.props.title ? (
            <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                item>

                <Toolbar>
                    <Box
                        display="flex"
                        flexGrow="1"
                        alignItems="center"
                    >
                        <Typography
                            style={{ display: "contents" }}
                            variant="h4">
                            {this.props.title}
                        </Typography>
                    </Box>

                    {
                        this.props.new_path ?
                            <Fab
                                color="primary"
                                variant="extended"
                                href={this.props.new_path}>
                                <AddIcon />
                        Adicionar
                    </Fab>
                            : ""
                    }

                    {
                        this.props.import_xlsx_url ?
                            <Fab
                                color="primary"
                                variant="extended"
                                style={{marginLeft: 10}}
                                href={this.props.import_xlsx_url}>
                                <InsertDriveFileIcon />
                                Importar XLSX
                            </Fab>
                            : ""
                    }

                    {
                        this.props.invoice_email_by_competence_date_url ?
                            <Fab
                                color="primary"
                                variant="extended"
                                style={{marginLeft: 10}}
                                href={this.props.invoice_email_by_competence_date_url}>
                                <EmailIcon />
                                Enviar Email
                            </Fab>
                            : ""
                    }
                </Toolbar>

                
            </Grid>
        ) : ""
    }
}