import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default class FlashMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true
        }

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(event) {
        this.setState({ open: !this.state.open });
    }

    render() {

        const kind = this.props.kind == 'error' ? 'error' : 'success';

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={this.state.open}
                onClose={this.handleClose}
            >
                <Alert 
                    severity={kind} 
                    elevation={6} 
                    variant="filled"
                    onClose={this.handleClose}>
                        { this.props.message }
                    </Alert>
            </Snackbar>
        );
    }
}