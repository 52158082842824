import React from 'react';
import { Button, Container, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';

export default class ContractImportForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const inputToken = (
            <TextField
                id="authenticity_token"
                name="authenticity_token"
                type="hidden"
                value={this.props.token}>
            </TextField>
        )

        return (
            <Container>
                <Typography variant="h5">Importar Contratos</Typography>

                <form
                    action={this.props.import_path}
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    method="post">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        {inputToken}

                        <Grid
                            item>
                            <TextField
                                label="Arquivo"
                                id="file"
                                type="file"
                                name="file">
                            </TextField>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Importar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Voltar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}