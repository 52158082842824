import React from 'react';
import { Button, Container, InputLabel, Grid, Select, TextField } from '@material-ui/core';
import SearchFormTitle from './SearchFormTitle';
import dateFormatter from './Utils/dateFormatter';
import maskDateComponent from './Utils/maskDateComponent';

export default class ContractSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            start_date_gteq: dateFormatter(props.start_date_gteq) || '',
            end_date_lteq: dateFormatter(props.end_date_lteq) || '',
            billing_day_eq: props.billing_day_eq || '',
            client_id_eq: props.client_id_eq || '',
            service_type_id_eq: props.service_type_id_eq || '',
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id

        this.setState({
            [id]: value
        });
    }

    render() {
        const optionsForClientSelect = this.props.client_options.map((option, i) => {
            return (
                <option key={`client_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        const optionsForServiceTypeSelect = this.props.service_type_options.map((option, i) => {
            return (
                <option key={`service_type_option_${option.value}`} value={ option.value }>
                    { option.label }
                </option>
            );
        });

        const titleItem = (
            <SearchFormTitle
                {...this.props}
            />
        );

        return (
            <Container>
                { this.props.title ? titleItem : ""}

                <form
                    action={this.props.search_path}
                    acceptCharset="UTF-8"
                    method="get">

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <TextField
                                        label="Data Inicial"
                                        id="start_date_gteq"
                                        name="q[start_date_gteq]"
                                        value={this.state.start_date_gteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item>
                                    <TextField
                                        label="Data Final"
                                        id="end_date_lteq"
                                        name="q[end_date_lteq]"
                                        value={this.state.end_date_lteq}
                                        onChange={this.handleInputChange}
                                        InputProps={{
                                            inputComponent: maskDateComponent
                                        }}>
                                    </TextField>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>
                                <Grid
                                    item>
                                    <TextField
                                        label="Dia do Faturamento"
                                        id="billing_day_eq"
                                        name="q[billing_day_eq]"
                                        value={this.state.billing_day_eq}
                                        onChange={this.handleInputChange}>
                                    </TextField>
                                </Grid>

                                <Grid
                                    item>
                                    <InputLabel id={`client_id_eq_label`}>Cliente</InputLabel>
                                    <Select
                                        labelId={`client_id_eq_label`}
                                        id={`client_id_eq`}
                                        native
                                        name={`q[client_id_eq]`}
                                        value={this.state.client_id_eq || ""}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="">Selecione um Cliente</option>

                                        {optionsForClientSelect}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item>

                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid
                                    item>
                                    <InputLabel id={`service_type_id_eq_label`}>Tipo de Serviço</InputLabel>
                                    <Select
                                        labelId={`service_type_id_eq_label`}
                                        id={`service_type_id_eq`}
                                        native
                                        name={`q[service_type_id_eq]`}
                                        value={this.state.service_type_id_eq || ""}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="">Selecione um Tipo de Serviço</option>

                                        {optionsForServiceTypeSelect}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                        Filtrar
                                    </Button>

                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        href={this.props.index_path}>
                                        Limpar Filtros
                                    </Button>
                                </Grid>
                                
                                {
                                    this.props.export_xlsx_url ?
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                data-turbolinks="false"
                                                href={this.props.export_xlsx_url}>
                                                Exportar XLSX
                                           </Button>
                                        </Grid> :
                                        ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}